<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-row
        v-if="$route.name === 'retailers-management-catalogue-management-retailer-catalogue-create'"
        class="p-2"
      >
        <b-col>
          <h4>Retailer Catalogue</h4>
        </b-col>
      </b-row>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refRetailerCatalogueTable"
        class="position-relative"
        :items="fetchRetailerCatalogue"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Description -->
        <template #cell(description)="data">
          {{ data.item.description.pos_description }}
          <br>
          <small class="text-muted">{{ data.item.description.short_description }}</small>
        </template>

        <!-- Column: Barcode -->
        <template #cell(barcode)="data">
          {{ data.item.barcode.bar_code }}
        </template>

        <!-- Column: VAT Slab -->
        <template #cell(vatslab)="data">
          {{ data.item.vatslab.value }}
          <br>
          <small class="text-muted">{{ data.item.vatslab.description }}</small>
        </template>

        <!-- Column: Has Ask Price -->
        <template #cell(has_ask_price)="data">
          {{ data.item.has_ask_price === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Category -->
        <template #cell(category)="data">
          {{ data.item.category.description }}
        </template>

        <!-- Column: Category -->
        <template #cell(subcategory)="data">
          {{ data.item.subcategory && data.item.subcategory.description ? data.item.subcategory.description : '' }}
        </template>

        <!-- Column: Unit Of Measure  -->
        <template #cell(unit_of_measure)="data">
          {{ data.item.unit_of_measure.description }}
          <br>
          <small class="text-muted">{{ data.item.unit_of_measure.code }}</small>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          {{ data.item.type === 'R' ? 'Retail' : data.item.type }}
        </template>

        <!-- Column: Is Weighable -->
        <template #cell(is_weightable)="data">
          {{ data.item.is_weightable === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Sellable -->
        <template #cell(is_sellable)="data">
          {{ data.item.is_sellable === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Allowed for Sale -->
        <template #cell(is_allowed_for_sale)="data">
          {{ data.item.is_allowed_for_sale === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Countable -->
        <template #cell(is_countable)="data">
          {{ data.item.is_countable === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Countable -->
        <template #cell(is_discountable)="data">
          {{ data.item.is_discountable === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Applicable Inventory Transaction -->
        <template #cell(applicable_inventory_tansactions)="data">
          {{ data.item.applicable_inventory_tansactions === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Available On POS -->
        <template #cell(is_available_on_pos)="data">
          {{ data.item.is_available_on_pos === 'Y' ? 'Yes' : 'No' }}
        </template>

        <!-- Column: Is Available On Ecom -->
        <template #cell(is_available_on_ecom)="data">
          {{ data.item.is_available_on_ecom === 'Y' ? 'Yes' : 'No' }}
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useRetailerCatalogueTable from './useRetailerCatalogueTable'
import retailerCatalogueStoreModule from '../../retailerCatalogueStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const RETAILER_CATALOGUE_APP_STORE_MODULE_NAME = 'app-retailer-catalogue'

    // Register module
    if (!store.hasModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME)) store.registerModule(RETAILER_CATALOGUE_APP_STORE_MODULE_NAME, retailerCatalogueStoreModule)

    const {
      fetchRetailerCatalogue,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRetailerCatalogueTable,
      refetchData,
    } = useRetailerCatalogueTable()

    return {
      fetchRetailerCatalogue,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRetailerCatalogueTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
