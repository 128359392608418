import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function useRetailerCatalogueTable() {
  // Use toast
  const toast = useToast()

  const refRetailerCatalogueTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'description' },
    { key: 'barcode' },
    { key: 'cost_price' },
    { key: 'price' },
    { key: 'vatslab', label: 'VAT Slab' },
    { key: 'has_ask_price' },
    { key: 'category', sortable: false },
    { key: 'subcategory', sortable: false },
    { key: 'sku' },
    { key: 'unit_of_measure' },
    { key: 'type' },
    { key: 'is_weightable' },
    { key: 'plu' },
    { key: 'is_sellable' },
    { key: 'is_allowed_for_sale' },
    { key: 'is_countable' },
    { key: 'is_discountable' },
    { key: 'applicable_inventory_tansactions' },
    { key: 'is_available_on_pos' },
    { key: 'is_available_on_ecom' },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchId = ref(router.currentRoute.params.id)

  const dataMeta = computed(() => {
    const localItemsCount = refRetailerCatalogueTable.value ? refRetailerCatalogueTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refRetailerCatalogueTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchRetailerCatalogue = (ctx, callback) => {
    store
      .dispatch('app-retailer-catalogue/fetchRetailerCatalogue', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        branchId: branchId.value,
      })
      .then(response => {
        const records = response.data.data[0].products
        callback(records)
        totalRecords.value = response.data.data[0].total
      })
      .catch(() => {
        callback([])
        totalRecords.value = 0
      })
  }

  return {
    fetchRetailerCatalogue,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRetailerCatalogueTable,
    refetchData,
  }
}
