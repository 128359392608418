import {
  fetchCategoriesAPI,
  fetchRetailerCatalogueAPI,
  procCategoryAPI,
  procSubCategoryAPI,
  uploadRetailerCatalogueAPI,
  validateRetailerCatalogueAPI,
} from '@/services/apis'

export default {
  namespaced: true,
  state: {
    categories: [],
  },
  getters: {
    categoryOptions(state) {
      if (state.categories.length <= 0) {
        return []
      }
      return state.categories.map(obj => ({
        label: obj.description,
        value: obj.category_id,
      }))
    },
  },
  mutations: {
    SET_CATEGORY_OPTIONS(state, data) {
      state.categories = data
    },
  },
  actions: {
    // *===============================================---*
    // *--------- CATEGORIES ---- ---------------------------------------*
    // *===============================================---*
    async fetchCategories(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchCategoriesAPI, { params: queryParams })
        ctx.commit('SET_CATEGORY_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async procCategory(ctx, params) {
      try {
        return await this.$axios.post(procCategoryAPI, params)
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- SUB CATEGORY ---- ---------------------------------------*
    // *===============================================---*
    async procSubCategory(ctx, params) {
      try {
        return await this.$axios.post(procSubCategoryAPI, params)
      } catch (error) {
        return error
      }
    },

    // *===============================================---*
    // *--------- RETAILER CATALOGUE ---- ---------------------------------------*
    // *===============================================---*
    async fetchRetailerCatalogue(ctx, queryParams) {
      try {
        return await this.$axios.get(fetchRetailerCatalogueAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async validateRetailerCatalogue(ctx, params) {
      try {
        const formData = new FormData()
        formData.append('branchId', params.branchId)
        if (params.catalogs && params.catalogs.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const index in params.catalogs) {
            if (Object.hasOwnProperty.call(params.catalogs, index)) {
              formData.append(`catalogs[${index}][category_id]`, params.catalogs[index].category_id)
              formData.append(`catalogs[${index}][catalog]`, params.catalogs[index].catalog)
            }
          }
        }
        const response = await this.$axios.post(validateRetailerCatalogueAPI, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              const percent = Math.floor((loaded * 100) / total)

              if (percent < 100) {
                ctx.commit('appLoaderConfig/SET_UPLOAD_PERCENT', percent, { root: true })
              }
            },
          })
        ctx.commit('appLoaderConfig/SET_UPLOAD_PERCENT', 100, { root: true })
        return response
      } catch (error) {
        return error
      }
    },
    async uploadRetailerCatalogue(ctx, params) {
      try {
        const formData = new FormData()
        formData.append('operation', 'U')
        formData.append('branchId', params.branchId)
        if (params.catalogs && params.catalogs.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const index in params.catalogs) {
            if (Object.hasOwnProperty.call(params.catalogs, index)) {
              formData.append(`catalogs[${index}][category_id]`, params.catalogs[index].category_id)
              formData.append(`catalogs[${index}][catalog]`, params.catalogs[index].catalog)
            }
          }
        }
        const response = await this.$axios.post(uploadRetailerCatalogueAPI, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              const { loaded, total } = progressEvent
              const percent = Math.floor((loaded * 100) / total)

              if (percent < 100) {
                ctx.commit('appLoaderConfig/SET_UPLOAD_PERCENT', percent, { root: true })
              }
            },
          })

        ctx.commit('appLoaderConfig/SET_UPLOAD_PERCENT', 100, { root: true })
        return response
      } catch (error) {
        return error
      }
    },
  },
}
